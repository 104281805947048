<template>
  <div class="ft20">
    <div class="flex space alcenter">
      <div class="ft20 cl-main ftw600">{{active_name}}报名与签到</div>
      <a-button @click="$router.go(-1)">返回</a-button>
    </div>
    <div class="mt20">
      <div class="pd20 bg-w">
        <div class="wxb-table-gray">
          <a-table rowKey="look_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas" :loading="loading" :rowKey="(record, index) => { return index }">

            <div class="flex alcenter center" slot="vip" slot-scope="record,index">
              <img v-if="record.elder.cover_img!=null" :src="record.elder.cover_img" class="member-index-face" />
              <img v-else src="../../assets/image/woyaoyou_head_defult@2x.png" class="member-index-face" />
              <div class="ml5" style="text-align: left;">
                <div>{{record.elder.name}}</div>
                <div class="mt2">{{record.elder.mobile}}</div>
              </div>
            </div>

            <div class="flex alcenter center" slot="integral" slot-scope="integral,record">
              <div>{{record.integral_num.type==1 ? '+' : '-'}}{{record.integral_num.integral}}</div>
            </div>

            <div class="flex alcenter center" slot="gender" slot-scope="gender,record">
              {{gender==1 ? '女' : '男'}}
            </div>
          </a-table>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {listMixin} from '../../common/mixin/list.js';

export default {
  mixins: [listMixin],
  components:{},
  data() {
    return {
      active_id:0,
      active_name:'',
      loading:false,
      addLoading:false,
      pagination: {
        current: 1,
        pageSize: 10, //每页中显示10条数据
        total: 0,
      },
      columns: [
        {title: 'ID',dataIndex: 'id',align: 'center',width:100,ellipsis: true},
        {title: '长者姓名',key: 'vip',align: 'center',width:200,scopedSlots: {customRender: 'vip'}},
        {title: '性别',dataIndex: 'gender',align: 'center',width:100,scopedSlots: {customRender: 'gender'}},
        {title: '报名时间',dataIndex: 'application_time_format',align: 'center',ellipsis: true},
        {title: '签到时间',dataIndex: 'sign_time_format',align: 'center',ellipsis: true},
        {title: '积分',dataIndex: 'integral',align: 'center',width:200,scopedSlots: {customRender: 'integral'}},
      ],
      datas: [],
    }
  },
  created() {
    if (!this.$route.query.id) {
      this.active_id=0;
    } else {
      this.active_id = parseInt(this.$route.query.id);
    }
    if (this.$route.query.name) {
      this.active_name = this.$route.query.name;
    }
  },
  methods: {
    onLink(record){
      window.open(record.file_url)
    },
    getLists(){
      if(this.loading==true) return;
      this.loading=true;
      this.$http.api('admin/getActivityJoinList',{
        active_id:parseInt(this.$route.query.id),
        limit:this.pagination.pageSize,
        page:this.pagination.current,
      }).then(res=>{
        this.pagination.total=res.total;
        this.pagination.showTotal=(total)=>"共"+res.total+"条";
        this.datas=res.list;
        this.level=res.level;
        this.loading=false;
      }).catch(res=>{
        this.loading=false;
      })
    },

    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.getLists();
    },
  }
}
</script>

<style>
@media screen and (max-width: 1450px) {
  .look-index-item {
    border: 1px solid #EBEDF5;
    width: 99%;
    height: 140px;
    margin-right: 1%;
    margin-bottom: 1%;
    padding: 10px;
  }
}

@media screen and (min-width:1451px) {
  .look-index-item {
    border: 1px solid #EBEDF5;
    width: 49%;
    height: 140px;
    margin-right: 1%;
    margin-bottom: 1%;
    padding: 10px;
  }
}

.look-index-item:hover {
  border: none;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04);
}

.look-index-item-operate {
  display: none;
}

.look-index-item:hover .look-index-item-operate {
  display: block;
}

.member-index-face {
  width: 44px;
  height: 44px;
  border-radius: 44px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
  border: 2px solid #FFFFFF;
}

.member-index-action {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: #FFFFFF;
  border: 1px solid #EBEDF5;
  text-align: center;
  line-height: 32px;
}
</style>
